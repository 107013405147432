import React, { useReducer, useState } from "react";
import { Box, Label, Input, Textarea, Button, Alert, Flex, Styled } from "theme-ui";
import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required("Inserire un nome"),
  email: yup.string().email("Inserire una email valida").required("Inserire una email"),
  subject: yup.string().required("Inserire un oggetto"),
  message: yup.string().required("Inserire un test"),
});

type StatusType = "PENDING" | "SUCCESS" | "IDLE" | "ERROR";

interface State {
  name: string;
  email: string;
  subject: string;
  message: string;
  status: StatusType;
}

const INITIAL_STATE: State = {
  name: "",
  email: "",
  subject: "",
  message: "",
  status: "IDLE",
};

const reducer = (state: State, action) => {
  switch (action.type) {
    case "updateFiledValue":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "updateStatus":
      return { ...state, status: action.status };
    case "reset":
    default:
      return INITIAL_STATE;
  }
};

const pendingStyles = {
  position: "relative",
  ":before": {
    backgroundColor: "white",
    borderRadius: "0.25rem",
    content: "''",
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0.65,
  },
  ":after": {
    animation: "loading 1s infinite ease-out",
    backgroundColor: "blue",
    borderRadius: "50%",
    content: "''",
    height: "7rem",
    width: "7rem",
    position: "absolute",
    left: "calc(50% - 3rem)",
    top: "calc(50% - 3rem)",
  },
  "@keyframes loading": {
    from: {
      opacity: 1,
      transform: "scale(0.001)",
    },
    to: {
      opacity: 0,
      transform: "scale(1)",
    },
  },
};

const ContactMailForm = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  function validateField(name, value) {
    yup
      .reach(schema, name)
      .validate(value)
      .then((valid) => {
        setErrors({ ...errors, [name]: null });
      })
      .catch((e) => {
        setErrors({ ...errors, [name]: e.errors });
      });
  }

  const setStatus = (status: StatusType) => dispatch({ type: "updateStatus", status });

  const updateFiledValue = (field) => (event) => {
    validateField(field, event.target.value);
    dispatch({
      type: "updateFiledValue",
      field,
      value: event.target.value,
    });
  };

  function handleBlur(event) {
    const { name, value } = event.target;
    validateField(name, value);
  }

  const onHandleSubmit = (event) => {
    event.preventDefault();

    setStatus("PENDING");

    fetch("/.netlify/functions/contact", {
      method: "POST",
      body: JSON.stringify(state),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setStatus("SUCCESS");
      })
      .catch((res) => {
        console.error(res);
        setStatus("ERROR");
      });
  };

  if (state.status === "SUCCESS") {
    return (
      <Flex>
        <Alert
          sx={{
            backgroundColor: "#4caf50",
          }}
        >
          L'email è stata spedita, grazie!
        </Alert>
        <Button
          sx={{
            ml: 2,
          }}
          type="reset"
          onClick={() => dispatch({ type: "reset" })}
        >
          Reset{" "}
        </Button>
      </Flex>
    );
  }

  function Errors({ errors }) {
    if (!errors) return null;

    return (
      <Styled.ul className="error">
        {errors.map((error, i) => (
          <Styled.li key={i}>{error}</Styled.li>
        ))}
      </Styled.ul>
    );
  }

  const addPendingStyles = state.status === "PENDING" ? pendingStyles : {};

  const formIsValid = state.name !== "" && state.email !== "" && state.subject !== "" && state.message !== "";

  return (
    <>
      {state.status === "ERROR" && (
        <Alert
          sx={{
            backgroundColor: "#ff5252",
          }}
        >
          Errore invio email! Riprova..
        </Alert>
      )}

      <Box
        as="form"
        onSubmit={onHandleSubmit}
        noValidate
        sx={{
          ...addPendingStyles,
        }}
      >
        <Label htmlFor="name">Nome</Label>
        <Input
          name="name"
          id="name"
          mb={3}
          required={true}
          placeholder="Io tuo nome / società"
          value={state.name}
          onChange={updateFiledValue("name")}
          /*           onBlur={handleBlur} */
        />
        <Errors errors={errors["name"]} />

        {/*         <Label htmlFor="company">Azienda *</Label>
        <Input name="company" id="company" mb={3} placeholder="Space Inc." /> */}
        <Label htmlFor="email">E-mail</Label>
        <Input
          name="email"
          id="email"
          mb={3}
          required
          placeholder="La tua email"
          value={state.email}
          onChange={updateFiledValue("email")}
        />
        <Errors errors={errors["email"]} />

        <Label htmlFor="subject">Oggetto</Label>
        <Input
          name="subject"
          id="subject"
          mb={3}
          required
          placeholder="Motivo del contatto"
          value={state.subject}
          onChange={updateFiledValue("subject")}
        />
        <Errors errors={errors["subject"]} />

        <Label htmlFor="message">Messaggio</Label>
        <Textarea
          name="message"
          id="message"
          rows="6"
          mb={3}
          required
          placeholder="Scrivi un messaggio"
          value={state.message}
          onChange={updateFiledValue("message")}
        />
        <Errors errors={errors["message"]} />

        <Button
          variant="simple"
          disabled={!formIsValid}
          sx={{
            color: "black",
            "&:disabled": {
              opacity: 0.7,
            },
          }}
        >
          Invia
        </Button>
      </Box>
    </>
  );
};

export default ContactMailForm;
