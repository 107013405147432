/* eslint react/prop-types: 0 */
import React from "react";
import { preToCodeBlock } from "mdx-utils";
import { Text } from "@theme-ui/components";
import Code from "../components/code";
import Title from "../components/title";
import { Link } from "theme-ui";

const components = {
  Text: ({ children, ...props }) => <Text {...props}>{children}</Text>,
  Title: ({ children, text, ...props }) => (
    <Title text={text} {...props}>
      {children}
    </Title>
  ),
  a: Link,
  pre: (preProps) => {
    const props = preToCodeBlock(preProps);
    // if there's a codeString and some props, we passed the test
    if (props) {
      return <Code {...props} />;
    }
    // it's possible to have a pre without a code in it
    return <pre {...preProps} />;
  },
  wrapper: ({ children }) => <>{children}</>,
};

export default components;
