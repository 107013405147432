/** @jsx jsx */
import { jsx, Grid } from "theme-ui";
import SkillCard from "./skill-card";
import { useAllPluralsightSkill } from "../hooks/use-all-pluralsight-skill";
import React from "react";

function SkillGrid() {
  const { edges } = useAllPluralsightSkill();
  // const edges = { edges: null };
  return (
    <Grid
      gap={2}
      sx={{
        gridTemplateColumns: [
          "repeat(auto-fill, minmax(150px, 1fr))",
          "repeat(auto-fill, minmax(200px, 1fr))",
          "repeat(auto-fill, minmax(200px, 1fr))",
        ],
        justifyItems: "strech",
        alignItems: "center",
      }}
    >
      {Object.values(edges).map((item) => (
        <div key={item.node.id}>
          <SkillCard {...item.node} />
        </div>
      ))}
    </Grid>
  );
}

export default SkillGrid;
