import { useStaticQuery, graphql } from "gatsby";

export const useAllPluralsightSkill = () => {
  const { allPluralsightSkill } = useStaticQuery(
    graphql`
      query PluralsightQuery {
        allPluralsightSkill(sort: { fields: score, order: DESC }) {
          edges {
            node {
              id
              thumbnailUrl
              title
              type
              url
              code
              dateCompletedStr: dateCompleted(formatString: "YYYY-MM-DD")
              dateCompleted
              level
              score
              percentile
            }
          }
        }
      }
    `
  );
  return allPluralsightSkill;
};
