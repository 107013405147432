import { merge } from "theme-ui";
import { tailwind } from "@theme-ui/presets";
import { darken, lighten, hue } from "@theme-ui/color";

export const palette = {
  link: "rgb(0, 175, 240)",
};

const theme = merge(tailwind, {
  initialColorModeName: `light`,
  useCustomProperties: true,
  colors: {
    text: "rgb(35, 48, 68)",
    primary: "rgb(0, 155, 240)", // 'tailwind.colors.purple[7]',
    primaryHover: "rgb(0, 175, 240)",
    secondary: `#5f6c80`,
    heading: "#233044",
    heading0: "black",
    toggleIcon: tailwind.colors.gray[8],
    divide: "rgb(0, 175, 240)",
    muted: tailwind.colors.gray[8],
    background: "#FFFFFF",

    myprimary: `#ffa600`,
    mysecondary: `#182f3e`,
    serviceCardBg: tailwind.colors.green[1],
    mysecondary2: tailwind.colors.gray[6],

    // darkMuted: darken(0.3, '#F7F6F9'),
    hero: "#182f3e",
    heroaccent: "#FFFF00", //  '#00ff00',
    linkColor: "rgb(0, 175, 240)",
    linkShadow: tailwind.colors.blue[2],
    modes: {
      dark: {
        text: tailwind.colors.gray[3],
        primary: "#FFDD00",
        primaryHover: "#FFFF00",
        secondary: `#7f8ea3`,
        heading: tailwind.colors.gray[4],
        heading0: "white",
        toggleIcon: tailwind.colors.gray[4],
        background: `#1A202C`,
        divide: tailwind.colors.gray[8],
        muted: tailwind.colors.gray[8],
        background: "#233044",

        myprimary: `#182f3e`,
        mysecondary: `#FFFF00`,
        serviceCardBg: "#182f3e",
        mysecondary2: `#FFFF00`,
        hero: `white`,
        heroaccent: `#FFFF00`,
        linkColor: `#FFFF00`,
        linkShadow: tailwind.colors.yellow[2],
      },
    },
  },
  fonts: {
    serif: 'merriweather, Georgia,Cambria,"Times New Roman",Times,serif',
    body: `"IBM Plex Sans", -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
  },
  styles: {
    root: {
      color: `text`,
      backgroundColor: `background`,
      margin: 0,
      padding: 0,
      boxSizing: `border-box`,
      textRendering: `optimizeLegibility`,
      WebkitFontSmoothing: `antialiased`,
      MozOsxFontSmoothing: `grayscale`,
    },

    p: {
      fontSize: [1, 1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
    },

    a: {
      color: "linkColor",
      fontFamily: "body",
      cursor: "pointer",
      fontWeight: "medium",
      textDecoration: "none",
      boxShadow: (theme) => `inset 0 -3px ${theme.colors.linkShadow}`,
      transition: `ease-in .05s border-bottom`,
      "&:hover": {
        textDecoration: "none",
        color: "black",
        boxShadow: (theme) => `inset 0 -24px ${theme.colors.linkShadow}`,
      },
    },
    ul: {
      li: {
        fontSize: [1, 1, 2],
        letterSpacing: `-0.003em`,
        lineHeight: `body`,
        "--baseline-multiplier": 0.179,
        "--x-height-multiplier": 0.35,
      },
      ".error": {
        color: "red",
      },
    },
    ol: {
      li: {
        fontSize: [1, 1, 2],
        letterSpacing: `-0.003em`,
        lineHeight: `body`,
        "--baseline-multiplier": 0.179,
        "--x-height-multiplier": 0.35,
      },
    },
    h1: {
      variant: `text.heading`,
      fontSize: [5, 6, 7],
      mt: 4,
    },
    h2: {
      variant: `text.heading`,
      color: "heading0",
      fontSize: [4, 5, 6],
      mt: 4,
    },
    h3: {
      variant: `text.heading`,
      fontSize: [3, 4, 5],
      mt: 4,
    },
    h4: {
      variant: `text.heading`,
      fontSize: [2, 3, 4],
      mt: 3,
    },
    h5: {
      variant: `text.heading`,
      fontSize: [1, 2, 3],
      mt: 3,
    },
    h6: {
      variant: `text.heading`,
      fontSize: 1,
      mb: 2,
    },
    blockquote: {
      borderLeftColor: `primary`,
      borderLeftStyle: `solid`,
      borderLeftWidth: `6px`,
      mx: 0,
      pl: 4,
      p: {
        fontStyle: `italic`,
      },
    },
    table: {
      width: `100%`,
      my: 4,
      borderCollapse: `separate`,
      borderSpacing: 0,
      [[`th`, `td`]]: {
        textAlign: `left`,
        py: `4px`,
        pr: `4px`,
        pl: 0,
        borderColor: `muted`,
        borderBottomStyle: `solid`,
      },
    },
    th: {
      verticalAlign: `bottom`,
      borderBottomWidth: `2px`,
      color: `heading`,
    },
    td: {
      verticalAlign: `top`,
      borderBottomWidth: `1px`,
    },
    hr: {
      mx: 0,
    },
    textarea: {},
  },

  header: {
    backgroundColor: "#FFFFDD",
  },
  layout: {
    container: {
      padding: [3, 4],
      maxWidth: `1024px`,
    },
  },
  text: {
    heading: {
      fontFamily: `heading`,
      fontWeight: `heading`,
      lineHeight: `heading`,
      color: `heading`,
    },
  },
  dividers: {
    bottom: {
      borderBottomStyle: `solid`,
      borderBottomWidth: `1px`,
      borderBottomColor: `primary`,
      pb: 3,
    },
    top: {
      borderTopStyle: `solid`,
      borderTopWidth: `1px`,
      borderTopColor: `primary`,
      pt: 3,
    },
  },
  links: {
    secondary: {
      boxShadow: "none",
      color: `secondary`,
      textDecoration: `underline`,
      ":hover": {
        color: `heading`,
        textDecoration: `underline`,
      },
      ":focus": {
        color: `heading`,
      },
    },
    listItem: {
      fontSize: [1, 2, 3],
      color: `text`,
    },
    navbutton: {
      fontWeight: "medium",
      boxShadow: "none",
      ":hover": {
        textDecoration: "none",
      },
      "&.active": {
        color: "green",
      },
    },
    social: {
      boxShadow: "none",
      ":hover": {
        textDecoration: "none",
        boxShadow: (theme) => `inset 0px -6px ${theme.colors.linkShadow}`,
      },
    },
  },
  forms: {
    label: {
      fontSize: 1,
      fontWeight: "bold",
    },
    input: {
      borderColor: "linkColor",
      "&:focus": {
        borderColor: "linkColor",
        boxShadow: (t) => `0 0 0 2px ${t.colors.linkColor}`,
        outline: "none",
      },
      /*       '&:required:valid': {
        borderColor: 'palegreen',
        boxShadow: (t) => `0 0 0 2px palegreen`,
        outline: 'none',
      },
      '&:invalid': {
        borderColor: 'salmon',
        boxShadow: (t) => `0 0 0 2px salmon`,
        outline: 'none',
      }, */
    },
    select: {
      borderColor: "linkColor",
      "&:focus": {
        borderColor: "linkColor",
        boxShadow: (t) => `0 0 0 2px ${t.colors.linkColor}`,
        outline: "none",
      },
    },
    textarea: {
      borderColor: "linkColor",
      "&:focus": {
        borderColor: "linkColor",
        boxShadow: (t) => `0 0 0 2px ${t.colors.linkColor}`,
        outline: "none",
      },
    },
    slider: {
      bg: "muted",
    },
  },
  navbutton: {},
});

export default theme;
