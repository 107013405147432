/** @jsx jsx */
import { jsx, Grid, Flex, Box, Heading, useThemeUI } from "theme-ui";

const SkillCard = ({ id, thumbnailUrl, title, level, score, dateCompletedStr }) => (
  <Flex
    sx={{
      border: "1px solid #3182CE",
      borderRadius: 8,
      alignItems: "center",
      justifyItems: "space-between",
      flexDirection: "column",
      p: 1,
      height: "250px",
      position: "relative",
    }}
  >
    <img
      src={thumbnailUrl}
      width={100}
      height={100}
      alt={title}
      sx={{
        p: 0,
        m: -3,
      }}
    />
    <Heading
      as="h4"
      sx={{
        p: 2,
        textAlign: "center",
        textOverflow: "ellipsis",
        whiteSpace: "wrap",
        width: "100%",
      }}
    >
      {title}
    </Heading>
    <Box sx={{ mx: "auto" }} />
    <Heading
      as="h5"
      sx={{
        display: "block",
        width: "100%",
        position: "absolute",
        bottom: "92px",
        p: 2,
        color: "indigo",
        bg: "#BEE3F8",
        textAlign: "center",
      }}
    >
      {dateCompletedStr}
    </Heading>

    <Heading
      as="h2"
      sx={{
        display: "block",
        width: "100%",
        position: "absolute",
        bottom: "40px",
        p: 2,
        color: "indigo",
        bg: "#BEE3F8",
        textAlign: "center",
      }}
    >
      {level}
    </Heading>

    <Heading
      as="h1"
      sx={{
        position: "absolute",
        bottom: 2,
        textAlign: "center",
        color: "2C5282",
      }}
    >
      {score}
    </Heading>
  </Flex>
);

export default SkillCard;
